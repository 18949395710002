import React, { useContext } from "react";

// images
import arrow_image from "../../../assets/images/arrow_middle_background.png";

// helpers
import { getBase64ImgSrc } from "../../../helpers/getBase64ImageSrc";

// hooks
import { useCurrentBlogFetch } from "./use-current-blog";
import { useWindowSize } from "../../../hooks/use-window-size/use-window-size";

// components
import Spinner from "../../../components/spinner/Spinner";

// context
import { blogContext } from "../../../context/blog-provider/BlogProvider";

// styles
import styles from "./CurrentBlog.screen.module.scss";

export function CurrentBlogScreen() {
  const { blogById } = useContext(blogContext);

  const { width } = useWindowSize();

  const { isBlogLoading } = useCurrentBlogFetch();

  return (
    <section className={styles.currentBlogSection}>
      {isBlogLoading ? (
        <div className={styles.blogLoader}>
          <Spinner size="large" />
        </div>
      ) : blogById ? (
        <>
          <div className={styles.blogHeader}>
            <div className={styles.imageWrapper}>
              <div className={styles.image}>
                <img
                  src={getBase64ImgSrc(blogById.picture)}
                  alt={blogById.title}
                />
              </div>
              <div className={styles.backBlock}></div>
            </div>

            <div className={styles.blogWrapper}>
              <p className={styles.title}>{blogById.title}</p>
            </div>
          </div>

          <div className={styles.mainContent}>
            <img
              src={arrow_image}
              alt="Arrow"
              className={styles.topBackgroundImage}
            />
            {width >= 1024 ? (
              <img
                src={arrow_image}
                alt="Arrow"
                className={styles.bottomBackgroundImage}
              />
            ) : null}

            <div
              className={styles.mainText}
              dangerouslySetInnerHTML={{
                __html: blogById.content || "",
              }}
            />
          </div>
        </>
      ) : (
        <h4>Unfortunately, there is no data available for this article.</h4>
      )}
    </section>
  );
}
