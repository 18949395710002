import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import { API_URL_COURSE, API_URL_VIDEO } from "./CourseProvider.consts";

// schemas
import {
  allCoursesSchema,
  allVideosSchema,
  courseSchema,
  videoSchema,
} from "./CourseProvider.schemas";

// types
import type {
  AddCourseFormType,
  AddVideoFormType,
  CourseContext,
  CourseProviderProps,
  CourseType,
  VideoType,
} from "./CourseProvider.types";

export const courseContext = React.createContext({} as CourseContext);

export const CourseProvider: FunctionComponent<CourseProviderProps> = (
  props
) => {
  const { api } = useContext(apiContext);

  const { children } = props;

  const [coursesData, setCoursesData] = useState<CourseType[] | null>(null);
  const [courseById, setCourseById] = useState<CourseType | null>(null);
  const [videosData, setVideosData] = useState<VideoType[] | null>(null);
  const [videoById, setVideoById] = useState<VideoType | null>(null);

  const [isCoursesLoading, setIsCoursesLoading] = useState(false);

  const getCourses = async () => {
    try {
      if (!coursesData) {
        setIsCoursesLoading(true);
        const response = await api(API_URL_COURSE, {}, allCoursesSchema);

        if (response) {
          const allCourses = response.data;

          setCoursesData(allCourses);
          return allCourses;
        }
      }

      return null;
    } catch (error) {
      throw error;
    } finally {
      setIsCoursesLoading(false);
    }
  };

  const getCourseById = async (courseId: string) => {
    try {
      const response = await api(
        `${API_URL_COURSE}/${courseId}`,
        {},
        courseSchema
      );

      if (response) {
        const currentCourse = response;

        setCourseById(currentCourse);
        return currentCourse;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const addCourse = async (formData: AddCourseFormType) => {
    try {
      if (coursesData) {
        const courseResponse = await api(
          API_URL_COURSE,
          {
            method: "POST",
            data: formData,
          },
          courseSchema
        );

        if (courseResponse) {
          const updatedCourses = [courseResponse, ...coursesData];

          setCoursesData(updatedCourses);
          return courseResponse;
        }
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const updateCourse = async (
    courseId: string,
    formData: AddCourseFormType
  ) => {
    try {
      if (coursesData) {
        const updatedCourse = await api(
          `${API_URL_COURSE}/${courseId}`,
          {
            method: "PUT",
            data: formData,
          },
          courseSchema
        );

        const updatedCourses = coursesData.map((course) => {
          if (course.id === courseId) {
            return {
              ...course,
              ...updatedCourse,
            };
          }
          return course;
        });

        setCoursesData(updatedCourses);
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteCourse = async (courseId: string) => {
    try {
      if (coursesData) {
        await api(`${API_URL_COURSE}/${courseId}`, {
          method: "DELETE",
        });
        const updatedCourses = coursesData.filter(
          (course) => course.id !== courseId
        );

        setCoursesData(updatedCourses);
        return updatedCourses;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getVideos = async (limit?: number) => {
    try {
      if (!videosData) {
        const response = await api(
          `${API_URL_VIDEO}?limit=${limit ? limit : 1000}`,
          {},
          allVideosSchema
        );

        if (response) {
          const allVideos = response.data;

          setVideosData(allVideos);
          return allVideos;
        }
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getVideoById = async (videoId: string) => {
    try {
      const response = await api(
        `${API_URL_VIDEO}/${videoId}`,
        {},
        videoSchema
      );

      if (response) {
        const currentVideo = response;

        setVideoById(currentVideo);
        return currentVideo;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const addVideo = async (formData: AddVideoFormType) => {
    try {
      if (coursesData) {
        const videoResponse = await api(
          API_URL_VIDEO,
          {
            method: "POST",
            data: formData,
          },
          videoSchema
        );

        if (videoResponse) {
          const updatedCourses = coursesData.map((course) => {
            if (course.id === formData.courseId) {
              return {
                ...course,
                videos: course.videos
                  ? [...course.videos, videoResponse]
                  : [videoResponse],
              };
            }
            return course;
          });

          setCoursesData(updatedCourses);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const updateVideo = async (videoId: string, formData: AddVideoFormType) => {
    try {
      if (coursesData) {
        const videoResponse = await api(
          `${API_URL_VIDEO}/${videoId}`,
          {
            method: "PUT",
            data: formData,
          },
          videoSchema
        );

        if (videoResponse) {
          const updatedCourses = coursesData.map((course) => {
            if (course.id === formData.courseId) {
              const updatedVideos = course.videos?.map((video) => {
                if (video.id === videoId) {
                  return {
                    ...video,
                    ...videoResponse,
                  };
                }

                return video;
              });

              return { ...course, videos: updatedVideos };
            }
            return course;
          });

          setCoursesData(updatedCourses);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteVideo = async (videoId: string, courseId: string) => {
    try {
      if (coursesData) {
        await api(`${API_URL_VIDEO}/${videoId}`, {
          method: "DELETE",
        });

        const currentCourse = coursesData.find(
          (course) => course.id === courseId
        );

        if (currentCourse?.videos) {
          const currentCourseUpdatedVideos = currentCourse.videos.filter(
            (video) => video.id !== videoId
          );

          const updatedCourses = coursesData.map((course) => {
            if (course.id === currentCourse.id) {
              return {
                ...course,
                videos: currentCourseUpdatedVideos,
              };
            }
            return course;
          });

          setCoursesData(updatedCourses);
        }
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  const contextValue = useMemo(
    () => ({
      getCourses,
      getCourseById,
      addCourse,
      updateCourse,
      deleteCourse,
      getVideos,
      getVideoById,
      addVideo,
      updateVideo,
      deleteVideo,
      coursesData,
      courseById,
      videoById,
      isCoursesLoading,
    }),
    [
      getCourses,
      getCourseById,
      addCourse,
      updateCourse,
      deleteCourse,
      getVideos,
      getVideoById,
      addVideo,
      updateVideo,
      deleteVideo,
      coursesData,
      courseById,
      videoById,
      isCoursesLoading,
    ]
  );

  return (
    <courseContext.Provider value={contextValue}>
      {children}
    </courseContext.Provider>
  );
};
