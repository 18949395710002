import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import classnames from "classnames";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";

// helpers
import { truncateString } from "../../../../helpers/truncate-string";
import { formattedDate } from "../../../../helpers/format-date";
import { getBase64ImgSrc } from "../../../../helpers/getBase64ImageSrc";

// consts
import { PATHS } from "../../../../route/route.controls";

// types
import type { BlogType } from "../../../../context/blog-provider/BlogProvider.types";

// styles
import styles from "./BlogItem.module.scss";

type BlogItemProps = {
  blog: BlogType;
  bestArticle?: boolean;
};

export function BlogItem({ blog, bestArticle }: BlogItemProps) {
  const navigate = useNavigate();

  const { width } = useWindowSize();

  const generatedLink = generatePath(PATHS.currentBlog, {
    blogId: blog.id,
  });

  return (
    <div
      className={classnames(
        styles.container,
        bestArticle ? styles.bestArticleContainer : ""
      )}
      onClick={() => navigate(generatedLink)}
    >
      <div className={styles.imageWrapper}>
        <img src={getBase64ImgSrc(blog.picture)} alt={blog.title} />
      </div>
      <div className={styles.blogInfo}>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{blog.title}</p>
          <div
            dangerouslySetInnerHTML={{
              __html: truncateString(
                blog.content || "",
                width < 1440 && width > 1023 ? 250 : 450
              ),
            }}
            className={styles.description}
          />
        </div>

        <p className={styles.author}>
          {blog.author} | {formattedDate(blog.createdAt, false)}
        </p>
      </div>
    </div>
  );
}
