import React, { useCallback, useContext } from "react";

// context
import { cartContext } from "../../../../context/cart-provider/CartProvider";

// components
import Button from "../../../../components/button/Button";

// types
import type { CourseType } from "../../../../context/course-provider/CourseProvider.types";

// styles
import styles from "./CourseInfo.module.scss";

type CourseInfoProps = {
  course: CourseType;
};

export function CourseInfo({ course }: CourseInfoProps) {
  const { addCourseToCart } = useContext(cartContext);

  const handleAddCourseToCart = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      selectedCourse: CourseType
    ) => {
      e.preventDefault();
      e.stopPropagation();
      addCourseToCart(selectedCourse);
    },
    [addCourseToCart]
  );

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          <img src={course.picture} alt={course.title} />
        </div>
        <div className={styles.backBlock}></div>
      </div>

      <div className={styles.courseWrapper}>
        <p className={styles.preTitle}>Course</p>
        <div className={styles.infoWrapper}>
          <h2 className={styles.title}>{course.title}</h2>
          <div className={styles.videosWrapper}>
            {course.videos?.length} Videos
          </div>
          <div className={styles.pricesWrapper}>
            {course.discountPrice ? <p>€ {course.discountPrice}</p> : null}
            <div className={course.discountPrice ? styles.diagonalLine : ""}>
              <p className={course.discountPrice ? styles.oldPrice : ""}>
                € {course.price}
              </p>
            </div>
          </div>
          <div>
            <Button
              width="auto"
              onClick={(e) => handleAddCourseToCart(e, course)}
            >
              Buy Course
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
