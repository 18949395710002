import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// components
import Modal from "../../../../components/modal/Modal";
import Input from "../../../../components/input/Input";
import Button from "../../../../components/button/Button";
import Checkbox from "../../../../components/checkbox/Checkbox";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";

// types
import { SignUpUserType } from "../../../../context/user-provider/UserProvider.types";

// consts
import { INIT_AUTH_FORM_DATA } from "../../../../context/user-provider/auth.consts";
import { PATHS } from "../../../../route/route.controls";

// styles
import styles from "./LoginModal.module.scss";

type LoginModalProps = {
  isOpen: "auth" | "register" | "reset" | "register_cart" | null;
  openLoginModal: (type: "auth" | "register" | "reset") => void;
  onClose: () => void;
  onSubmit: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    formData: SignUpUserType
  ) => Promise<void>;
};

export function LoginModal({
  isOpen,
  openLoginModal,
  onClose,
  onSubmit,
}: LoginModalProps) {
  const { width } = useWindowSize();

  const [authFormData, setAuthFormData] =
    useState<SignUpUserType>(INIT_AUTH_FORM_DATA);
  const [isChecked, setIsChecked] = useState(false);

  const isAuthModal = isOpen === "auth";
  const isRegisterModal = isOpen === "register";
  const isNewRegisterModal = isOpen === "register_cart";
  const isResetModal = isOpen === "reset";

  const modalTitle = () => {
    switch (isOpen) {
      case "auth":
        return <p className={styles.title}>Log In</p>;
      case "register":
        return <p className={styles.title}>Sign Up</p>;
      case "register_cart":
        return (
          <p className={styles.newUserTitle}>
            Please <span>Sign Up</span> for buying the course
          </p>
        );
      default:
        return <p className={styles.title}>Change password</p>;
    }
  };

  const buttonTitle = useMemo(() => {
    if (isAuthModal) {
      return "Log In";
    }
    if (isRegisterModal || isNewRegisterModal) {
      return "Sign Up";
    }

    return "Send verification code";
  }, [isAuthModal, isRegisterModal, isNewRegisterModal]);

  const handleChangeCheckbox = useCallback(() => {
    setIsChecked((prev) => !prev);
  }, [setIsChecked]);

  const hadleCloseModal = useCallback(() => {
    onClose();
    setAuthFormData(INIT_AUTH_FORM_DATA);
  }, [onClose, setAuthFormData]);

  const isDisabledRegisterButton = useMemo(
    () =>
      !authFormData.email ||
      !authFormData.password ||
      !authFormData.confirm_password ||
      !authFormData.firstName ||
      !authFormData.lastName ||
      !isChecked,
    [authFormData, isChecked]
  );

  const validatePassword = useCallback(() => {
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,20}$/;

    const isValid = passwordRegex.test(authFormData.password);

    if (isValid || !authFormData.password) {
      return {
        error: false,
        message: "",
      };
    } else {
      return {
        error: true,
        message:
          "Password must be more than 7 characters (letters + numbers), and must include at least one uppercase letter.",
      };
    }
  }, [authFormData]);

  const isDisabledAuthButton = useMemo(
    () =>
      !authFormData.email || !authFormData.password || validatePassword().error,
    [authFormData, validatePassword]
  );

  const isDisabledResetButton = useMemo(
    () => !authFormData.email,
    [authFormData]
  );

  const disabledButton = useMemo(() => {
    return isAuthModal
      ? isDisabledAuthButton
      : isRegisterModal || isNewRegisterModal
      ? isDisabledRegisterButton
      : isDisabledResetButton;
  }, [
    isAuthModal,
    isRegisterModal,
    isNewRegisterModal,
    isDisabledRegisterButton,
    isDisabledAuthButton,
    isDisabledResetButton,
  ]);

  const handleChangeAuthData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setAuthFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={hadleCloseModal}
      width={width > 1440 ? "35%" : width > 1024 ? "60%" : "100%"}
      className={styles.modal}
      variant={width > 768 ? "withOutHeader" : "default"}
      size="fullScreen"
    >
      <form className={styles.form}>
        {modalTitle()}

        <Input
          placeholder="Email"
          id="auth-email"
          name="email"
          value={authFormData.email}
          onChange={handleChangeAuthData}
          required
        />

        {!isResetModal ? (
          <>
            <Input
              placeholder="Password"
              type="password"
              id="auth-password"
              name="password"
              value={authFormData.password}
              onChange={handleChangeAuthData}
              validation={validatePassword()}
              required
            />
            {isRegisterModal || isNewRegisterModal ? (
              <Input
                placeholder="Confirm password"
                type="password"
                id="auth-password"
                name="confirm_password"
                value={authFormData.confirm_password}
                onChange={handleChangeAuthData}
                validation={validatePassword()}
                required
              />
            ) : null}
          </>
        ) : null}

        {isRegisterModal || isNewRegisterModal ? (
          <>
            <Input
              placeholder="First name"
              id="auth-first-name"
              name="firstName"
              value={authFormData.firstName}
              onChange={handleChangeAuthData}
              required
            />
            <Input
              placeholder="Last name"
              id="auth-last-name"
              name="lastName"
              value={authFormData.lastName}
              onChange={handleChangeAuthData}
              required
            />

            <div className={styles.checkboxWrapper}>
              <Checkbox
                id="terms_and_conditions"
                name="terms"
                value="1"
                checked={isChecked}
                className={styles.authChekbox}
                onChange={handleChangeCheckbox}
                type="auth"
              />
              <p>
                I hereby declare that I have read understood and accept the T&C.{" "}
                <a
                  href={PATHS.termsAndConditions}
                  onClick={hadleCloseModal}
                  target="_blank"
                >
                  See more
                </a>
              </p>
            </div>
          </>
        ) : null}

        {isAuthModal ? (
          <div
            className={styles.forgotButton}
            onClick={() => openLoginModal("reset")}
          >
            Forgot password?
          </div>
        ) : null}
        <Button
          onClick={(e) => onSubmit(e, authFormData)}
          type="submit"
          color="secondary"
          disabled={disabledButton}
          className={styles.button}
        >
          {buttonTitle}
        </Button>
      </form>
    </Modal>
  );
}
