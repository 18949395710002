import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// icons
import { FailedIcon } from "../../../assets/icons/FailedIcon";

// hooks
import { useHeaderFetch } from "../../../components/header/use-header";
import { usePreviewModalFetch } from "../../../components/preview-cart-modal/use-preview-modal";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import Button from "../../../components/button/Button";
import { CartModal } from "../../../components/cart-modal/CartModal";

// styles
import styles from "./PaymentFailed.module.scss";

export function PaymentFailed() {
  const navigate = useNavigate();

  const [isOpenCartModal, setIsOpenCartModal] = useState(false);

  const { isOpenPreviewCart } = useHeaderFetch();

  const { filteredCart, totalPrice } = usePreviewModalFetch(isOpenPreviewCart);

  return (
    <section className={styles.section}>
      <div className={styles.mainContent}>
        <div className={styles.failedIconWrapper}>
          <FailedIcon />
        </div>

        <p className={styles.title}>Payment Failed</p>
        <p className={styles.description}>
          Something went wrong, we couldn’t process your payment. Contact our
          support if you have lost money.
        </p>
      </div>

      <div className={styles.buttonsWrapper}>
        <Button onClick={() => setIsOpenCartModal(true)} width="auto">
          Retry payment
        </Button>

        <Button
          onClick={() => navigate(PATHS.index)}
          variant="outline"
          width="auto"
        >
          Back to main
        </Button>
      </div>

      <CartModal
        isOpen={isOpenCartModal}
        onClose={() => setIsOpenCartModal(false)}
        cartProducts={filteredCart}
        total={totalPrice}
      />
    </section>
  );
}
