import { generatePath, useNavigate } from "react-router-dom";

// consts
import { PATHS } from "../../../../route/route.controls";

// types
import type { LearningCardType } from "../learning-block/LearningBlock.types";

// styles
import styles from "./LearningCard.module.scss";

type Props = {
  learningCard: LearningCardType;
};

export function LearningCard({ learningCard }: Props) {
  const navigate = useNavigate();

  const generatedLink = generatePath(PATHS.course, {
    courseId: learningCard.courseId,
  });

  return (
    <div
      className={styles.learningCard}
      onClick={() => navigate(generatedLink)}
    >
      <h6 className={styles.learningCardTitle}>{learningCard.title}</h6>
      <p className={styles.learningCardDescription}>
        {learningCard.description}
      </p>
      <img
        className={styles.icon}
        src={learningCard.icon}
        alt="learning card"
      />
    </div>
  );
}
