import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../../context/error/ErrorProvider";
import { storageContext } from "../../../../context/storage-provider/StorageProvider";

// types
import type { VideoType } from "../../../../context/course-provider/CourseProvider.types";
import { courseContext } from "../../../../context/course-provider/CourseProvider";

export function useVideoFetch(
  video: VideoType,
  isOpen: boolean,
  type?: "admin" | "user" | "guest"
) {
  const { error } = useContext(errorContext);
  const { getFileByKey } = useContext(storageContext);
  const { getVideoById } = useContext(courseContext);

  const [isVideoLoading, setIsVideoLoading] = useState(false);

  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const videoFetch = async (video: VideoType) => {
    const controller = new AbortController();
    const { signal } = controller;
    try {
      if (type !== "guest") {
        setIsVideoLoading(true);

        const videoResponse = await getVideoById(video.id);

        if (videoResponse?.source?.key) {
          const videoBlob = await getFileByKey(
            videoResponse?.source?.key,
            signal
          );

          if (videoBlob) {
            const url = URL.createObjectURL(videoBlob);
            setVideoUrl(url);
          }
        }
      }
    } catch (err) {
      error(err);
    } finally {
      setIsVideoLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      videoFetch(video);
    }
  }, [video, type, isOpen]);

  return {
    isVideoLoading,
    videoUrl,
  };
}
