import React, { FunctionComponent, useMemo, useState } from "react";

// consts
import { CART } from "./CartProvider.consts";

// types
import type {
  CartContext,
  CartProduct,
  CartProviderProps,
} from "./CartProvider.types";
import type { CourseType } from "../course-provider/CourseProvider.types";
import type { MembershipTypesType } from "../membership-provider/MembershipProvider.types";

export const cartContext = React.createContext({} as CartContext);

export const CartProvider: FunctionComponent<CartProviderProps> = (props) => {
  const { children } = props;

  const [cartProducts, setCartProducts] = useState<CartProduct | null>(null);
  const [cartCount, setCartCount] = useState(0);

  const getCartProducts = () => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { courses: [], membershipId: "", videos: [] };

    const amountCartItems =
      cart.courses.length + cart.videos.length + (cart.membershipId ? 1 : 0);

    setCartProducts(cart);
    setCartCount(amountCartItems);
  };

  const addCourseToCart = (course: CourseType) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { courses: [], membershipId: "", videos: [] };

    if (
      !cart.courses.some((cartCourse) => {
        return cartCourse === course.id;
      })
    ) {
      const addedCart = { ...cart, courses: [course.id, ...cart.courses] };

      localStorage.setItem(CART, JSON.stringify(addedCart));

      const amountCartItems =
        addedCart.courses.length +
        addedCart.videos.length +
        (addedCart.membershipId ? 1 : 0);

      return setCartCount(amountCartItems);
    }

    const currentCart = cart.courses.length
      ? cart
      : { ...cart, courses: [course.id] };

    const currentAmountCartItems =
      currentCart.courses.length +
      currentCart.videos.length +
      (currentCart.membershipId ? 1 : 0);

    localStorage.setItem(CART, JSON.stringify(currentCart));

    return setCartCount(currentAmountCartItems);
  };

  const addMembershipToCart = (membership: MembershipTypesType) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { courses: [], membershipId: "", videos: [] };

    const addedCart = { ...cart, membershipId: membership.id };

    localStorage.setItem(CART, JSON.stringify(addedCart));

    const amountCartItems =
      addedCart.courses.length +
      addedCart.videos.length +
      (addedCart.membershipId ? 1 : 0);

    return setCartCount(amountCartItems);
  };

  const addVideoToCart = (videoId: string) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { courses: [], membershipId: "", videos: [] };

    if (
      !cart.videos.some((cartVideo) => {
        return cartVideo === videoId;
      })
    ) {
      const addedCart = { ...cart, videos: [videoId, ...cart.videos] };

      localStorage.setItem(CART, JSON.stringify(addedCart));

      const amountCartItems =
        addedCart.courses.length +
        addedCart.videos.length +
        (addedCart.membershipId ? 1 : 0);

      return setCartCount(amountCartItems);
    }

    const currentCart = cart.courses.length
      ? cart
      : { ...cart, videos: [videoId] };

    const currentAmountCartItems =
      currentCart.courses.length +
      currentCart.videos.length +
      (currentCart.membershipId ? 1 : 0);

    localStorage.setItem(CART, JSON.stringify(currentCart));

    return setCartCount(currentAmountCartItems);
  };

  const deleteCourseFromCart = (courseId: string) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { courses: [], membershipId: "", videos: [] };

    const updatedCart: CartProduct = {
      ...cart,
      courses: cart.courses.filter((cartCourse) => cartCourse !== courseId),
    };

    const amountCartItems =
      updatedCart.courses.length +
      updatedCart.videos.length +
      (updatedCart.membershipId ? 1 : 0);

    localStorage.setItem(CART, JSON.stringify(updatedCart));
    return setCartCount(amountCartItems);
  };

  const deleteMembershipFromCart = (membershipId: string) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { courses: [], membershipId: "", videos: [] };

    const updatedCart: CartProduct = {
      ...cart,
      membershipId: membershipId ? "" : "",
    };

    const amountCartItems =
      updatedCart.courses.length +
      updatedCart.videos.length +
      (updatedCart.membershipId ? 1 : 0);

    localStorage.setItem(CART, JSON.stringify(updatedCart));
    return setCartCount(amountCartItems);
  };

  const deleteVideoFromCart = (videoId: string) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct = cartString
      ? JSON.parse(cartString)
      : { courses: [], membershipId: "", videos: [] };

    const updatedCart: CartProduct = {
      ...cart,
      videos: cart.videos.filter((cartVideo) => cartVideo !== videoId),
    };

    const amountCartItems =
      updatedCart.courses.length +
      updatedCart.videos.length +
      (updatedCart.membershipId ? 1 : 0);

    localStorage.setItem(CART, JSON.stringify(updatedCart));
    return setCartCount(amountCartItems);
  };

  const contextValue = useMemo(
    () => ({
      cartProducts,
      cartCount,
      getCartProducts,
      addCourseToCart,
      addMembershipToCart,
      addVideoToCart,
      deleteCourseFromCart,
      deleteMembershipFromCart,
      deleteVideoFromCart,
    }),
    [
      cartProducts,
      cartCount,
      getCartProducts,
      addCourseToCart,
      addMembershipToCart,
      deleteCourseFromCart,
      deleteMembershipFromCart,
    ]
  );

  return (
    <cartContext.Provider value={contextValue}>{children}</cartContext.Provider>
  );
};
