import React, { useCallback, useContext } from "react";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";

// images
import arrow_image from "../../../../assets/images/arrow_middle_background.png";

// components
import { VideoItem } from "../../../client/my-courses-user-screen/video-item/VideoItem";
import Button from "../../../../components/button/Button";

// context
import { cartContext } from "../../../../context/cart-provider/CartProvider";

// types
import type { CourseType } from "../../../../context/course-provider/CourseProvider.types";

import styles from "./CourseVideos.module.scss";

type CourseVideosProps = {
  course: CourseType;
};

export function CourseVideos({ course }: CourseVideosProps) {
  const { addCourseToCart } = useContext(cartContext);

  const { width } = useWindowSize();

  const handleAddCourseToCart = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      selectedCourse: CourseType
    ) => {
      e.preventDefault();
      e.stopPropagation();
      addCourseToCart(selectedCourse);
    },
    [addCourseToCart]
  );

  return (
    <section className={styles.section}>
      {width >= 768 ? (
        <img src={arrow_image} alt="Arrow" className={styles.backgroundImage} />
      ) : null}

      <h3 className={styles.title}>Course videos</h3>

      {course.videos && course.videos.length ? (
        <div className={styles.mainContent}>
          {course.videos
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
            .map((video, index) => (
              <VideoItem
                video={video}
                key={video.id}
                videoIndex={index}
                type="guest"
              />
            ))}
        </div>
      ) : (
        <h4>At the moment, this course does not have any videos</h4>
      )}

      <div className={styles.buttonWrapper}>
        <Button width="auto" onClick={(e) => handleAddCourseToCart(e, course)}>
          Buy Course
        </Button>
      </div>
    </section>
  );
}
