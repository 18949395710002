import React, { useCallback, useContext, useRef, useState } from "react";
import classnames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

// components
import { CoursesMenu } from "./courses-menu/CoursesMenu";
import { PreviewCartModal } from "../preview-cart-modal/PreviewCartModal";
import { CartModal } from "../cart-modal/CartModal";
import Button from "../button/Button";

// images
import logo from "../../assets/images/logo.png";

// icons
import { ArrowDownIcon } from "../../assets/icons/ArrowDownIcon";
import { CartIcon } from "../../assets/icons/CartIcon";

// hooks
import { useHeaderFetch } from "./use-header";
import { usePreviewModalFetch } from "../preview-cart-modal/use-preview-modal";
import { useAuth } from "../../context/user-provider/use-auth";
import useClickOutside from "../../hooks/use-click-outside/use-click-outside";
import useLockBodyScroll from "../../hooks/use-lock-body-scroll/use-lock-body-scroll";
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";

// consts
import { PATHS } from "../../route/route.controls";
import { guestNavigationsLinks, userNavigationLinks } from "./Header.consts";

// context
import { sessionContext } from "../../context/session-provider/SessionProvider";

// styles
import styles from "./Header.module.scss";

type HeaderProps = {
  type?: "guest" | "user" | "admin";
  openLoginModal?: (type: "auth" | "register" | "register_cart") => void;
};

export function Header({ type = "guest", openLoginModal }: HeaderProps) {
  const { getRoleFromToken } = useContext(sessionContext);

  const [isOpenCartModal, setIsOpenCartModal] = useState(false);

  const { isOpenPreviewCart, cartCount, setIsOpenPreviewCart } =
    useHeaderFetch();

  const { isCartLoading, filteredCart, totalPrice } =
    usePreviewModalFetch(isOpenPreviewCart);

  const { handleLogOut } = useAuth();

  const { pathname: currentURL } = useLocation();
  const navigate = useNavigate();

  const clickRef = useRef(null);
  const clickCoursesRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const [show, setShow] = useState(false);
  useClickOutside(clickRef, () => setShow(false));

  const { width } = useWindowSize();

  useLockBodyScroll(isOpen);

  const handleOpenCart = useCallback(() => {
    setIsOpenPreviewCart(false);
    setIsOpenCartModal(true);
  }, [setIsOpenCartModal]);

  const role = getRoleFromToken();
  const isAdmin = role === "admin";

  const isUserHeader = type === "user";
  const isAdminHeader = type === "admin";

  const handleOpenLoginModal = useCallback(
    (type: "auth" | "register") => {
      if (openLoginModal) {
        openLoginModal(type);
      }
    },
    [openLoginModal]
  );

  return (
    <header
      className={classnames(
        styles.header,
        isAdminHeader
          ? styles.adminHeader
          : isUserHeader
          ? styles.userHeader
          : styles.guestHeader
      )}
    >
      <div className={styles.contentWrapper}>
        <picture
          onClick={() => navigate(PATHS.index)}
          className={styles.picture}
        >
          <source media="(min-width: 768px)" srcSet={logo} />

          <img src={logo} alt="power-house-logo" />
        </picture>

        <input
          id="menu__toggle"
          type="checkbox"
          className={styles.menuToggle}
          checked={isOpen}
          onChange={(e) => setIsOpen(!e.target.checked)}
        />

        <label
          className={styles.menuBtn}
          htmlFor="menu__toggle"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <span></span>
        </label>

        <div
          className={classnames(styles.cartIcon, styles.mobileCartIcon)}
          onClick={() => setIsOpenPreviewCart(true)}
        >
          {cartCount ? (
            <div className={styles.cartAmount}>
              <p>{cartCount}</p>
            </div>
          ) : null}

          <CartIcon />
        </div>

        <div className={styles.navigationLinks}>
          <div className={styles.generalNavigationLinks}>
            {guestNavigationsLinks.map((link) => (
              <React.Fragment key={link.title}>
                {link.title === "Courses" ? (
                  <>
                    <div>
                      <div
                        className={classnames(
                          styles.navigationLink,
                          styles.courseNavigationLink
                        )}
                        ref={clickCoursesRef}
                        onClick={
                          width < 1024
                            ? () => {
                                setIsOpen(false);
                                navigate(PATHS.mobileCourses);
                              }
                            : undefined
                        }
                      >
                        Courses{" "}
                        {width >= 1024 ? (
                          <>
                            <div className={styles.arrowIcon}>
                              <ArrowDownIcon
                                color="#fff"
                                className={classnames(
                                  styles.arrowIcon,
                                  show ? styles.activeIcon : null
                                )}
                              />
                            </div>

                            <div className={styles.coursesMenu}>
                              <CoursesMenu />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>

                    <div className={styles.separator} />
                  </>
                ) : (
                  <div
                    className={classnames(
                      styles.navigationLink,
                      currentURL === link.to ? styles.activeNavigationLink : ""
                    )}
                    onClick={() => {
                      setIsOpen(false);
                      navigate(link.to);
                    }}
                  >
                    {link.title}
                  </div>
                )}
              </React.Fragment>
            ))}

            <div className={styles.separator} />
          </div>

          <div
            className={styles.cartIcon}
            onClick={() => setIsOpenPreviewCart(true)}
          >
            {cartCount ? (
              <div className={styles.cartAmount}>
                <p>{cartCount}</p>
              </div>
            ) : null}

            <CartIcon />
          </div>

          {role ? (
            <>
              <div className={styles.userNavigationLinks}>
                {userNavigationLinks.map((link) => (
                  <div
                    className={classnames(
                      styles.navigationLink,
                      currentURL === link.to ? styles.activeNavigationLink : ""
                    )}
                    onClick={() => {
                      setIsOpen(false);
                      navigate(link.to);
                    }}
                    key={link.title}
                  >
                    {link.title}
                  </div>
                ))}

                {isAdmin ? (
                  <>
                    <div
                      className={classnames(
                        styles.navigationLink,
                        currentURL === PATHS.adminMemberships ||
                          currentURL === PATHS.adminCourses ||
                          currentURL === PATHS.adminBlog ||
                          currentURL === PATHS.adminCourses
                          ? styles.activeNavigationLink
                          : ""
                      )}
                      onClick={() => {
                        setIsOpen(false);
                        navigate(PATHS.adminMemberships);
                      }}
                    >
                      Admin Panel
                    </div>{" "}
                    <div className={styles.separator} />
                  </>
                ) : null}
              </div>

              {width < 768 ? (
                <Button
                  onClick={() => {
                    handleLogOut();
                    setIsOpen(false);
                  }}
                  color="secondary"
                  className={styles.mobileButton}
                >
                  Log Out
                </Button>
              ) : (
                <div className={styles.navigationLink} onClick={handleLogOut}>
                  Log out
                </div>
              )}
            </>
          ) : width < 1024 ? (
            <>
              <Button
                onClick={() => {
                  handleOpenLoginModal("auth");
                  setIsOpen(false);
                }}
                color="secondary"
                className={styles.mobileButton}
              >
                Log In
              </Button>
              <Button
                onClick={() => {
                  handleOpenLoginModal("register");
                  setIsOpen(false);
                }}
                variant="outline"
                className={styles.signUpButton}
              >
                Sign Up
              </Button>
            </>
          ) : (
            <>
              <div
                className={styles.navigationLink}
                onClick={() => handleOpenLoginModal("register")}
              >
                Sign up
              </div>
              <div
                className={styles.navigationLink}
                onClick={() => handleOpenLoginModal("auth")}
              >
                Log in
              </div>
            </>
          )}
        </div>
      </div>

      <PreviewCartModal
        isOpen={isOpenPreviewCart}
        onClose={() => setIsOpenPreviewCart(false)}
        isCartLoading={isCartLoading}
        filteredCart={filteredCart}
        totalPrice={totalPrice}
        handleOpenCart={handleOpenCart}
      />

      <CartModal
        isOpen={isOpenCartModal}
        onClose={() => setIsOpenCartModal(false)}
        cartProducts={filteredCart}
        total={totalPrice}
        openLoginModal={openLoginModal}
      />
    </header>
  );
}
